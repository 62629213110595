
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


.yellow-section {
  background-color: #FDE624; 
  padding: 0; 
  margin-right: 0;
  padding-right: 0;
  min-height: 100vh;
}
.navbar{
  justify-content: right;
  padding: 0;
  margin-right: 0;
  font-family: "Playfair Display", serif;
}
.nav-link {
  display: inline-block; 
  padding: 0.5rem 1rem; 
  margin: 0; 
  text-decoration: none;
  color: inherit; 
}

.nav-link:hover {
  text-decoration: underline; 
}
.logo-section{
  background-color: #F4F3EF;
  min-height: 50vh;
}


@media (max-width: 768px) {
  .img-fluid {
    max-width: 50%; 
    max-height: 50%; 
  }
}


@media (min-width: 992px) {
  .img-fluid {
    max-width: 20%; 
    max-height: 20%;
  }
}
.img-fluid {
  display: block;  
  margin: 0 auto;
}
.container{
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 550;
}
.portal{
  font-size: 30px;
  text-align: center;
}
.time-link {
  color: black;
  display: inline-block;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.3s ease-in-out;
}

.time-link:hover {
  border-bottom-color: black; 
}
.clock {
  width: 20vw; 
  height: 20vw; 
  max-width: 150px; 
  max-height: 150px; 
  background-color: #F4F3EF;
  border-radius: 50%;
  position: relative;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clock .number{
--rotation: 0;
position: absolute;
width: 100%;
height: 100%;
text-align: center;
transform: rotate(var(--rotation));
}
.clock .number1{--rotation: 30deg;}
.clock .number2{--rotation: 60deg;}
.clock .number3{--rotation: 90deg;}
.clock .number4{--rotation: 120deg;}
.clock .number5{--rotation: 150deg;}
.clock .number6{--rotation: 180deg;}
.clock .number7{--rotation: 210deg;}
.clock .number8{--rotation: 240deg;}
.clock .number9{--rotation: 270deg;}
.clock .number10{--rotation: 300deg;}
.clock .number11{--rotation: 330deg;}

.clock .hand{
  --rotation: 0;
  position: absolute;
  bottom: 50%;
  left: 50%;
  width:2px;
  height: 30%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: black;
  transform-origin: bottom;
  transform: translatex(-50%) rotate(calc(var(--rotation)* 1deg));
}
.clock::after {
  content: '';
  position: absolute;
  background-color: black;
  z-index: 11;
  width: 5px;
  height: 5px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  }
.clock .hand.second{
width: 1px;
height: 30%;
background-color: black;
}
.clock .hand.minute{
  width: 2px;
  height: 32%;
  background-color: black;
  }
  .clock .hand.hour{
    width: 2px;
    height: 25%;
    background-color: black;
    }
    .hand.hour::after, .hand.minute::after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      border: 5px solid transparent;
      border-bottom-color: black; /* Arrow color */
      border-top: 0;
      margin-left: -5px;
      width: 0;
      height: 0;
    }
 
    .clock .number {
      font-size: 1vw
    }
    .portal .margin.text-size {
      margin-bottom: 0; 
    }
    
    .krmashan {
        font-size: '2rem'; 
        font-family: "Playfair Display", serif;
        color: 'white';
        cursor: 'pointer';
        text-shadow: 0 0 16px #897a7a;
    }
    .dateBox {
      max-width: 100%;
      height: 1px;
      background-color: #ffffff40;
      backdrop-filter: blur(3px);
      border-radius: 10px;
      box-shadow: 0 0 37px #e7af4a;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: clamp(30px, 2vw, 30px);
      font-family: "Playfair Display", serif;
      color:white;
      text-shadow: 0 0 16px #897a7a;
    }
   