.calendar {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    max-width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  }
  
  .day {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .day:hover {
    background-color: #e3e4e6;
    cursor: pointer;
  }
  
  .day-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
  }
  
  .date {
    font-size: 12px;
    color: #666;
  }
  
  .calendar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  .calendar-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    width: 100%; 
    padding: 5px;
    margin-bottom: 20px;
  }
  
  .calendar-header button {
    border: none;
    padding: 5px 10px; 
    border-radius: 20px;
    cursor: pointer;
  }
  
  .calendar-header button:hover {
    background-color: #ccc;
  }
  
  .calendar-header span {
    font-weight: bold;
    text-align: center;
    flex-grow: 1;
  }
  
  .kurdish-day {
    color: rgba(0, 0, 0, 0.837);
    font-weight: bold;
  }
  
  .today {
    background-color: yellow;
  }
  